import React from 'react';
import {Link} from "react-router-dom";
import "./TopMenu.scss";

function TopMenu(props) {
    const contactForm = "16cfc750-df65-44e0-87a0-b66b854e97cc";

    return (
        <div className="top-menu">
            <Link to={{
                pathname: `/`
            }}>
                HOME
            </Link>
            <Link to={{
                pathname: `/info`
            }}>
                INFO
            </Link>
            {contactForm ? <Link to={{pathname: `/contact`}}>CONTACT</Link> : null}
        </div>
    );
}

export default TopMenu;
